
























































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { QrcodeStream } from 'vue-qrcode-reader'
import { ValidationObserver } from 'vee-validate'
import BInput from '@/components/validation/BInput.vue'
import BAutocomplete from '@/components/validation/BAutocomplete.vue'

@Component({
  components: {
    QrcodeStream,
    'AppForm': ValidationObserver,
    'AppInput': BInput,
    'AppAuto': BAutocomplete,
  }
})
export default class ScanReturSuratJalan extends Vue {
  //#region COMPUTED
  get loading() {
    return this.$store.getters.loading
  }
  get error() {
    return this.$store.getters.error
  }
  get existData() {
    return this.$store.getters['scan/returSuratJalan/existData']
  }

  get listCart() {
    return this.$store.getters['scan/returSuratJalan/cart']
  }
  get listSuratJalan() {
    return this.$store.getters.sharedTrackingSuratJalan
  }
  get orderedItems() {
    return this.listCart.filter((x:any) => x.qty > 0)
  }
  //#endregion
  //#region DATA
  $refs!: { observer: InstanceType<typeof ValidationObserver> }
  productHeaders:Array<object> = [
    { text: 'Kode Produk', value: 'itemCode' },
    { text: 'Nama Produk', value: 'itemName' },
    { text: 'Qty', value: 'qty' },
  ]
  barcode:any = null
  message:any = null
  camera:any = 'auto'
  menu:boolean = false
  dialog:boolean = false
  tab:any = null
  tabItems:any = [ 'Detail', 'Items' ]
  defaultItem:any = {
    date: null,
    barcode: null,
    trackingSuratJalanId: 0,
    listDetail: []
  }
  editedItem:any = {
    date: null,
    barcode: null,
    trackingSuratJalanId: 0,
    listDetail: []
  }
  productSearch:any = null
  //#endregion
  //#region WATCH
  @Watch('dialog')
  dialogChanged(val:boolean) {
    val || this.close()
  }
  //#endregion
  //#region METHODS
  async onDecode(val:any) {
    if (this.loading) return
    await this.$store.dispatch('scan/returSuratJalan/ExistData', { barcode: val })

    if (this.error && this.error.error) return

    this.camera = 'off'
    if (this.existData) {
      await this.$store.dispatch('scan/returSuratJalan/ScanData', { barcode: val })
      this.barcode = val
      this.message = this.error.message
    } else {
      await this.$store.dispatch('scan/returSuratJalan/ReadCart')
      this.editedItem.trackingSuratJalanId = this.listSuratJalan.find((x:any) => x.name == val).id
      this.dialog = true
    }
  }
  saveItem() {
    this.$refs.observer.validate()
      .then(async success => {
        if (!success) return

        const payload = {
          date: this.editedItem.date,
          trackingSuratJalanId: this.editedItem.trackingSuratJalanId,
          listDetail: this.orderedItems
        }
        await this.$store.dispatch('scan/returSuratJalan/ScanNewData', payload)

        this.message = this.error.message
        this.barcode = this.listSuratJalan.find((x:any) => x.id == this.editedItem.trackingSuratJalanId).name

        this.close()
      })
  }
  close() {
    this.dialog = false
    this.$nextTick(() => {
      this.editedItem = Object.assign({}, this.defaultItem)
      const resetForm = this.$refs.observer
      if (resetForm) resetForm.reset()
    })
  }
  reset() {
    this.camera = 'auto'
    this.barcode = null
    this.message = null
  }
  //#endregion
  //#region CREATED
  created() {
    this.$store.dispatch('ReadSharedTrackingSuratJalan')
  }
  //#endregion
  //#region MOUNTED
  //#endregion
}
